body {
    margin: 0;
    font-family: Avenir, Montserrat, Corbel, 'URW Gothic', source-sans-pro, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: white;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* For webkit browsers (Chrome, Safari, newer versions of Opera, Edge) */
::-webkit-scrollbar {
    width: 5px;
    height: 10px;
}

::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 200px;
}

::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}

/* For Firefox */
* {
    scrollbar-width: none;
    scrollbar-color: rgba(12, 26, 44, 0.6) rgba(12, 26, 44, 1);
}
